html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  /* background: #6b705c; */
  background-color: #383d3a;
  background-image: linear-gradient(180deg, #525b55 0%, #498249 50%, #1f231f 100%);
  
  /* cursor: none; */
  cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxMCIgZmlsbD0id2hpdGUiLz48L3N2Zz4='),
    auto;
}

#background-img { mix-blend-mode: multiply; }

#root::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('../public/view.svg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  pointer-events: none;
}
